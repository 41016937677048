<template>
  <header :class="{ 'mobile-header': screenWidth < 835 }">
    <div class="logo">
      <img alt="logo" src="../assets/images/header-logo.svg" @click="redirectToHomePage" />
    </div>
    <img
      v-if="screenWidth < 835"
      src="@/assets/images/mobile-menu.svg"
      alt="mobile-menu"
      class="mobile-menu-img"
      @click="showHideMenu" />
    <nav v-if="authManager.loggedIn" class="nav" :class="{ 'mobile-menu': screenWidth < 835, hide: mobileMenu }">
      <ul>
        <li style="margin-right: 100px">1-510-598-7013</li>
        <li>
          <router-link :to="{ name: 'insurance-navigator-page' }" style="margin-right: 20px">
            Start New Submission
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: 'all-submissions' }" style="margin-right: 20px">All Submissions</router-link>
        </li>
        <li>
          <router-link :to="{ name: 'login' }" @click="logoutUser">Log Out</router-link>
        </li>
      </ul>
    </nav>
  </header>
</template>

<script setup>
import { ref } from "vue";
import { useRouter } from "vue-router";

const router = useRouter();
import { useAuthStore } from "@/stores";

const screenWidth = ref(null);
const mobileMenu = ref(true);

const authManager = useAuthStore();

function displayWindowSize() {
  screenWidth.value = document.documentElement.clientWidth;
}

window.addEventListener("resize", displayWindowSize);

displayWindowSize();

function showHideMenu() {
  mobileMenu.value = mobileMenu.value ? false : true;
}

function redirectToHomePage() {
  router.push({ name: "insurance-navigator-page" });
}

async function logoutUser() {
  await authManager.logout();
  window.location.reload();
}
</script>

<style lang="scss" scoped>
@import "@/styles/components/_prior-header.scss";
</style>
